@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  /*text-align: center;*/
  height: 100%;
  /*background-color: #F2F2F2;*/
  /*background-color: yellow;*/
  max-width: 1920px;
  margin: auto;
  
}
h2 {
  font-size: 23px;
  font-weight: bold;
}
.cells {
  background-color: blue;
}

.btnModal {
  background-color: #083EA7;
  border: none;
  color: white;
  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  /*font-size: 16px;*/
  margin: 14px 10px;
  cursor: pointer;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
  border-radius: 100px;
  
  justify-self: start;
}

.btnModal:focus {
  outline: none;
}

.btnModal:hover {
  background-color: cornflowerblue;
}

.fc-day-today {
  /*background: #FFF !important;*/
  background-color: rgb(5, 193, 159, 0.1) !important;;
  border: none !important;
  border-top: 1px solid #ddd !important;
  font-weight: bold;
}

button[disabled] {
  opacity: 0.7;
}

.rti--container {
  --rti-border: #262626 !important;
  --rti-main: #262626 !important;
  background: white !important;
}

.rti--input {
  border: unset !important;
  padding: 0 20px !important;
}


.error-helper {
  color: #d32f2f
}

.scheduleList > li:not(:last-child) > .scheduleItemBorder {
  border-bottom: 1px solid #000;
  margin: 0px 34px;
}
