.hasError {
  border: 1px solid red !important;
}
.modified {
  border: 2px solid #ffcc33;
  border-radius: 20px;
  margin-bottom: 7px;
  color: #000;
  list-style-type: none;
}
.modifiedItem {
  border: 2px solid pink !important;
  border-radius: 20px;
  color: #000;
  list-style-type: none;
}

.dateLi {
    list-style-type: none;
}
.itemLi {
    list-style-type: none;
}
.olTillf {
    margin: 0px 35px 15px 35px;
}

.olTillf li:nth-child(odd) {
    background-color:rgb(205, 212, 215, 0.25);
}
.flexSch {
    display: flex;
    padding-right: 20px;
    justify-content: left;
    align-items: center;
    padding-left: 47px;
    margin-bottom: 20px;
}
.flexSch > div {
  font-weight: bold;
  padding-right: 20px;
}
.flexItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}
.daysleft {
    color: grey;
    font-size: 10px;
}
.flexDays {
    display: flex;
    align-items: center;
}
.flexDays > label {
   font-weight: bold;
   margin-right:15px;
}
.flexTime {
    display: flex;
    align-items: center;
}
.flexTime > input {
   font-size: 14px;
   border: 1px solid black;
   padding: 4px;
   border-radius: 20px;
   outline: none;
   background-color: transparent;
}

.flexTime input[type=time]:disabled {
    border: 1px solid rgb(117, 114, 114);
    color: rgb(117, 114, 114);
}

.flexTime > label {
    font-weight: bold;
    margin-right: 15px;
 }
.scheduleItemLabel {
    padding: 0px 10px;
    font-weight: bold;
    width: 120px;
}
.diva {
    padding: 0px 10px;
    font-weight: bold;
}

.container {
    margin: 50px;
    max-width: 1200px;
}

.top {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 30px;
}

.topLeft {
    display: flex;
    align-items: center;
    margin-left: 7px;
}
.topLeft > h2 {
    margin-right: 10px;
    font-size: 23px;
    font-weight: bold;
}
.topBtn {
    padding-left: 30px;
}

.topBtn > button {
    margin-right: 10px;
}
.flex1 {
    flex: 1;
}
.top p {
    font-size: 1.5rem;
    font-weight: 500;
}

.txtInfo {
    margin-bottom: 20px;
    color: rgba(38,38,38, 0.7);
}
.txtInfo span {
    color: #083EA7;
    cursor: pointer;
}

.messageBox {
    background: #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 16px 24px rgba(12, 0, 51, 0.1);
    border-radius: 0px 10px 10px 10px;
    padding: 30px;
}
.schemCircle {
    display: flex;
    align-items: center;
    margin-right: 34px;
}

.inputBorder {
    margin: 20px 35px;
    border: 1px solid grey;
    padding: 6px;
    flex: 1;
}
.inputShow {
    margin: 20px 20px;
    font-size: 18px;
    font-weight: bold;
    flex: 1;
    background-color: white;
    border: 1px solid Black;
}
.inputShow:disabled {
    border: none;
}
.schemCircle > p {
    font-size: 18px;
    font-weight: bold;
    margin-left: 10px;
}
.scheduleStartTR {
    font-weight: 500;
}
.scheduleItemsTR {
    margin-left: 40px;
    padding-left: 60px;
}

.scheduleTable {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}
.scheduleTable th {
    font-weight: 500;
}

.scheduleTable td {
    padding: 9px;
}
  
.scheduleTable tr:nth-child(odd) {
    background-color:rgb(205, 212, 215, 0.25);
}

.screeningHead {
    margin-bottom: 25px;
}

.bin {
    display: flex;
    align-items: center;
}

.btnReactivate {
    background-color: #083EA7;
    border: none;
    color: white;
    padding: 7px 22px;
    text-align: center;
    margin: 0px;
    text-decoration: none;
    display: inline-block;
    font-size: 0.85rem;
    font-weight: 500;
    outline: none;
    cursor: pointer;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 100px;
}
.btnReactivate:hover {
    opacity: 0.5;
}

.scheduleTable img {
    margin-left: 10px;
}

.scheduleTable img:hover {
    opacity: 0.5;
    cursor: pointer;
}

.btnSchedule {
    background-color: #01153d;
    border: none;
    color: white;
    padding: 7px 22px;
    text-align: center;
    margin: 10px 0px;
    text-decoration: none;
    display: inline-block;
    font-size: 0.85rem;
    font-weight: 500;
    outline: none;
    cursor: pointer;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 100px;
}
.btnSchedule:hover {
    opacity: 0.5;
}
.newSchedule {
    /*border: 1px solid #BFBFBF;*/
    border-radius: 0px 10px 10px 10px;
    background-color: white;
    box-shadow: 0px 16px 24px rgba(12, 0, 51, 0.1);
    /*border-radius: 10px;*/
    margin-bottom: 30px;
    padding-bottom: 7px;
}
.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.datePick_Btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;   
}

.days {
    font-style: italic;
    color:#083EA7
}

.scheduleStart {
    border: 1px solid #BFBFBF;
    border-radius: 30px;
    padding: 6px;
    outline: none;
    text-align: center;
    width: 130px;
    color: rgb(117, 114, 114);
}
.ssEdit {
  border: 1px solid black;
  border-radius: 30px;
  padding: 6px;
  outline: none;
  text-align: center;
  width: 130px;
  color: Black;
  background-color: transparent;
}
.dayStart {
    border-radius: 30px;
    padding: 6px;
    font-size: 14px;
    outline: none;
    border: 1px solid black;
    background-color: transparent;
}
.dayStart:disabled {
  border: 1px solid rgb(117, 114, 114);
  color: rgb(117, 114, 114);
}

.slut {
    font-weight: bold;
}

.border {
    border: 1px solid #BFBFBF;
    border-radius: 30px;
    padding: 6px;

}

.btnWhite {
    background-color: white;
    border: 1px solid #BFBFBF;
    padding: 7px 22px;
    margin-bottom: 10px;
    text-decoration: none;
    display: inline-block;
    font-size: 0.85rem;
    font-weight: 500;
    outline: none;
    cursor: pointer;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 100px;
}
.btnWhite:hover {
    opacity: 0.5;
}

.meny {
    display: flex;   
}
.m1 {
    width: 180px;
    font-weight: bold;
    padding: 10px;
    border: 1px solid #BFBFBF;
    color: grey;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 10px 10px 0px 0px;
    text-align: center;
}
.mActive {
    font-weight: bold;
    padding: 10px;
    border: none;
    background-color: white;
    border-radius: 10px 10px 0px 0px;
}
.m1:hover {
    opacity: 0.5;
    cursor: pointer;
}
.mActive:hover {
    padding: 10px;
    background-color: white;
    cursor: pointer;
}
.delete {
    cursor: pointer;
    margin: 5px;
}
.delete:hover {
    opacity: 0.5;
}

.tillbaka {
    padding:30px;
}
.datePlaceholder {
    display: inline;
    border: 1px solid grey;
    padding: 7px 20px;
    border-radius: 30px;
}
.datePlaceholderStart {
    display: inline;
    border: 1px solid grey;
    padding: 7px 30px;
    border-radius: 30px;
}
.notActive {
    border: 1px solid rgb(212, 209, 209);
    padding: 33px 30px;
    border-radius: 30px;
    width: 90%;
}
.notActive > p {
    font-weight: 500;
}
.datePicker {
    border: 1px solid greenyellow;
    padding: 6px 12px;
    border-radius: 30px;
}
.backTxt {
  font-size: 20px;
  color: #083EA7;
  cursor: pointer;
}

.headerTime {
  width: 45%;
  font-size: 15px;
  font-weight: bold;
  justify-content: flex-start;
}

.headerTimeItem{
  margin-right: 20px;
  min-width: fit-content;
}

.headerTimeLabel {
  color: #808080;
  margin-right: 5px;
  
}

.headerStatus {
  width: 190px;
  color: #808080;
  font-size: 15px;
  font-weight: bold;
}
